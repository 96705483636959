import advertising from './advertising';
import depositsTop from './deposits';
import locations from './locations';
import metadata from './metadata';
import sitesettings from './sitesettings';
import user from './user';

export const reducers = {
  sitesettings,
  metadata,
  user,
  locations,
  advertising,
  depositsTop,
};
